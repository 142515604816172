import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel, FormControlModel } from '@common-src/model/form-control';
import { QueryPageModel } from '@common-src/model/query-model';
import CommonService from '@common-src/service/common';

/* 服务配置详情-节点-处理人配置-条件分派 */
export class WorkOrderConfigDetailEntityModel extends BaseEntityModel {
    @FormControl({
        label: '条件名称',
        type: FormControlType.TEXT,
        required: true,
        readonly: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '受理组',
        type: FormControlType.TREE_SELECT,
        optionsPromise: CommonService.getOrgs,
        relationKeys: ['userId'],
        required: true
    } as FormControlOptionModel)
    groupId: string = undefined;
    groupName: string = undefined;

    @FormControl({
        label: '受理人',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getOrgUserList,
        optionsPromiseParam: 'groupId',
        relationPath: `${BI_BASE_REQUEST_PATH}/orgs/getOrgUsers`
    } as FormControlOptionModel)
    userId: string = undefined;
    userName: string = undefined;

    assignmentId:string = undefined;

    // fieldMatches:Array<{fieldId:string, dataType:string, connectorType:string, nval:any, fval:number, operator:string, sval:string, options:Array<any>, id:string}>=[]
    fieldMatches = [];
    static getTableColumns() {
        return [
            {
                title: '序号',
                dataIndex: 'index',
                scopedSlots: { customRender: 'index' },
                width: 60
            },
            {
                title: '条件名称',
                dataIndex: 'name'
            },
            {
                title: '受理组',
                dataIndex: 'groupName'
            },
            {
                title: '受理人',
                dataIndex: 'userName'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}

export class WorkOrderConfigDetailQueryModel extends QueryPageModel {
    keyWords: string = '';

    toService() {
        const data: any = super.toService();
        return data;
    }
}
