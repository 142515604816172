var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "work-order-config-detail-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c(
                  "a-breadcrumb-item",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/dashboard/professional-relation" } },
                      [_vm._v("工单设置")]
                    ),
                  ],
                  1
                ),
                _c(
                  "a-breadcrumb-item",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/dashboard/work-order-config" } },
                      [_vm._v("服务配置")]
                    ),
                  ],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v(_vm._s(_vm.title))]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          _c(
            "a-layout",
            [
              _c(
                "a-layout-sider",
                { attrs: { theme: "light" } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: "流程节点", bordered: false } },
                    [
                      _c("a-tree", {
                        attrs: {
                          "selected-keys": _vm.SelectedGroupId,
                          "tree-data": _vm.treeData,
                          "expanded-keys": _vm.expandedKeys,
                        },
                        on: {
                          "update:selectedKeys": function ($event) {
                            _vm.SelectedGroupId = $event
                          },
                          "update:selected-keys": function ($event) {
                            _vm.SelectedGroupId = $event
                          },
                          "update:expandedKeys": function ($event) {
                            _vm.expandedKeys = $event
                          },
                          "update:expanded-keys": function ($event) {
                            _vm.expandedKeys = $event
                          },
                          select: _vm.treeSelect,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("a-layout-content", [
                _c(
                  "div",
                  { staticClass: "page-body-margin-left-16" },
                  [
                    _c(
                      "a-tabs",
                      {
                        attrs: { "active-key": _vm.selectedTab },
                        on: { change: _vm.tabChanged },
                      },
                      [
                        !_vm.selectedTreeData.task
                          ? _c(
                              "a-tab-pane",
                              { key: "form", attrs: { tab: "表单权限" } },
                              [
                                _c("a-table", {
                                  attrs: {
                                    columns: _vm.formColumns,
                                    "data-source": _vm.configDetail.form,
                                    pagination: false,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "index",
                                        fn: function (text, record, index) {
                                          return [
                                            _vm._v(
                                              " " + _vm._s(index + 1) + " "
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "checkBox",
                                        fn: function (
                                          text,
                                          record,
                                          index,
                                          scopedIndex
                                        ) {
                                          return [
                                            _c("a-checkbox", {
                                              model: {
                                                value:
                                                  record[scopedIndex.dataIndex],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    record,
                                                    scopedIndex.dataIndex,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "record[scopedIndex.dataIndex]",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    795769334
                                  ),
                                }),
                                _c("hr", {
                                  staticStyle: { "border-width": "0px" },
                                }),
                                _c(
                                  "jtl-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.saveForm },
                                  },
                                  [_vm._v("保存")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.selectedTreeData.task
                          ? _c(
                              "a-tab-pane",
                              {
                                key: "candidate",
                                staticStyle: { padding: "10px" },
                                attrs: { tab: "处理人配置" },
                              },
                              [
                                _c(
                                  "a-radio-group",
                                  {
                                    staticStyle: { margin: "0 0 10px 0" },
                                    on: { change: _vm.candidateChanged },
                                    model: {
                                      value: _vm.configDetail.candidate.type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.configDetail.candidate,
                                          "type",
                                          $$v
                                        )
                                      },
                                      expression: "configDetail.candidate.type",
                                    },
                                  },
                                  [
                                    _c(
                                      "a-radio",
                                      { attrs: { value: "User" } },
                                      [_vm._v("固定人")]
                                    ),
                                    _c(
                                      "a-radio",
                                      { attrs: { value: "Group" } },
                                      [_vm._v("固定组")]
                                    ),
                                    _c(
                                      "a-radio",
                                      { attrs: { value: "Role" } },
                                      [_vm._v("固定角色")]
                                    ),
                                    _c(
                                      "a-radio",
                                      { attrs: { value: "Profession" } },
                                      [_vm._v("专业分派")]
                                    ),
                                    _c(
                                      "a-radio",
                                      { attrs: { value: "OtherCondition" } },
                                      [_vm._v("动态分派")]
                                    ),
                                    _c(
                                      "a-radio",
                                      { attrs: { value: "Condition" } },
                                      [_vm._v("条件分派")]
                                    ),
                                  ],
                                  1
                                ),
                                _c("hr", {
                                  staticStyle: {
                                    border: "#cdcdcd 0.5px solid",
                                    "margin-bottom": "24px",
                                  },
                                }),
                                _vm.configDetail.candidate.type === "Group"
                                  ? [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "required-icon",
                                          staticStyle: {
                                            color: "red",
                                            "margin-right": "5px",
                                          },
                                        },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v("受理组： "),
                                      _c("a-tree-select", {
                                        staticClass: "margin-right-24",
                                        attrs: {
                                          placeholder: "请选择受理组",
                                          "tree-data":
                                            _vm.candidateGroupOptions,
                                        },
                                        on: { change: _vm.groupChanged },
                                        model: {
                                          value:
                                            _vm.configDetail.candidate.groupId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.configDetail.candidate,
                                              "groupId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "configDetail.candidate.groupId",
                                        },
                                      }),
                                    ]
                                  : _vm.configDetail.candidate.type === "Role"
                                  ? [
                                      _c(
                                        "a-radio-group",
                                        {
                                          staticStyle: { margin: "0 0 10px 0" },
                                          attrs: { "default-value": "creator" },
                                          model: {
                                            value:
                                              _vm.configDetail.candidate.roleId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.configDetail.candidate,
                                                "roleId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "configDetail.candidate.roleId",
                                          },
                                        },
                                        [
                                          _c(
                                            "a-radio",
                                            {
                                              style: _vm.radioStyle,
                                              attrs: { value: "creator" },
                                            },
                                            [_vm._v("创建人")]
                                          ),
                                          _c(
                                            "a-radio",
                                            {
                                              style: _vm.radioStyle,
                                              attrs: {
                                                value: "creator-leader",
                                              },
                                            },
                                            [_vm._v("创建人直属领导")]
                                          ),
                                          _c(
                                            "a-radio",
                                            {
                                              style: _vm.radioStyle,
                                              attrs: {
                                                value: "processor-leader",
                                              },
                                            },
                                            [_vm._v("处理人直属领导")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("hr", {
                                        staticStyle: { "border-width": "0px" },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "red",
                                            "margin-right": "5px",
                                          },
                                        },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v("默认受理组： "),
                                      _c("a-tree-select", {
                                        staticClass: "margin-right-24",
                                        attrs: {
                                          placeholder: "请选择受理组",
                                          "tree-data":
                                            _vm.candidateGroupOptions,
                                        },
                                        on: { change: _vm.groupChanged },
                                        model: {
                                          value:
                                            _vm.configDetail.candidate.groupId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.configDetail.candidate,
                                              "groupId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "configDetail.candidate.groupId",
                                        },
                                      }),
                                      _vm._v(" 默认受理人： "),
                                      _c("a-select", {
                                        attrs: {
                                          placeholder: "请选择受理人",
                                          options: _vm.userOptions,
                                        },
                                        model: {
                                          value:
                                            _vm.configDetail.candidate.userId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.configDetail.candidate,
                                              "userId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "configDetail.candidate.userId",
                                        },
                                      }),
                                      _c("hr", {
                                        staticStyle: { "border-width": "0px" },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "font-size": "small",
                                            color: "#cdcdcd",
                                            "margin-bottom": "10px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "若无法找到所选角色用户，工单分派到默认受理组"
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm.configDetail.candidate.type ===
                                    "Profession"
                                  ? [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "red",
                                            "margin-right": "5px",
                                          },
                                        },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v("默认受理组： "),
                                      _c("a-tree-select", {
                                        staticClass: "margin-right-24",
                                        attrs: {
                                          placeholder: "请选择受理组",
                                          "tree-data":
                                            _vm.candidateGroupOptions,
                                        },
                                        on: { change: _vm.groupChanged },
                                        model: {
                                          value:
                                            _vm.configDetail.candidate.groupId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.configDetail.candidate,
                                              "groupId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "configDetail.candidate.groupId",
                                        },
                                      }),
                                      _vm._v(" 默认受理人： "),
                                      _c("a-select", {
                                        attrs: {
                                          placeholder: "请选择受理人",
                                          options: _vm.userOptions,
                                        },
                                        model: {
                                          value:
                                            _vm.configDetail.candidate.userId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.configDetail.candidate,
                                              "userId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "configDetail.candidate.userId",
                                        },
                                      }),
                                      _c("hr", {
                                        staticStyle: { "border-width": "0px" },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "small",
                                            color: "#cdcdcd",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "若专业未配置受理组，工单分派到默认受理组"
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm.configDetail.candidate.type ===
                                    "OtherCondition"
                                  ? [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "red",
                                            "margin-right": "5px",
                                          },
                                        },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v("流程节点 "),
                                      _c("a-select", {
                                        attrs: {
                                          placeholder: "请选择流程节点",
                                          options: _vm.flowOptions,
                                        },
                                        model: {
                                          value:
                                            _vm.configDetail.candidate.actionId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.configDetail.candidate,
                                              "actionId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "configDetail.candidate.actionId",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-right": "20px",
                                            color: "#cdcdcd",
                                            "margin-top": "5px",
                                          },
                                        },
                                        [_vm._v("当前流程节点的最后处理人")]
                                      ),
                                    ]
                                  : _vm.configDetail.candidate.type ===
                                    "Condition"
                                  ? [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "red",
                                            "margin-right": "5px",
                                          },
                                        },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v("默认受理组： "),
                                      _c("a-tree-select", {
                                        staticClass: "margin-right-24",
                                        attrs: {
                                          placeholder: "请选择受理组",
                                          "tree-data":
                                            _vm.candidateGroupOptions,
                                        },
                                        on: { change: _vm.groupChanged },
                                        model: {
                                          value:
                                            _vm.configDetail.candidate.groupId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.configDetail.candidate,
                                              "groupId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "configDetail.candidate.groupId",
                                        },
                                      }),
                                      _vm._v(" 默认受理人： "),
                                      _c("a-select", {
                                        attrs: {
                                          placeholder: "请选择受理人",
                                          options: _vm.userOptions,
                                        },
                                        model: {
                                          value:
                                            _vm.configDetail.candidate.userId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.configDetail.candidate,
                                              "userId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "configDetail.candidate.userId",
                                        },
                                      }),
                                      _c("hr", {
                                        staticStyle: { "border-width": "0px" },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "font-size": "small",
                                            color: "#cdcdcd",
                                            "margin-bottom": "10px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "若以下所有条件都不满足，工单分派到默认受理组,选择默认受理组后，点击保存按钮进行条件配置"
                                          ),
                                        ]
                                      ),
                                      _vm.configDetail.candidate.type ===
                                        "Condition" &&
                                      _vm.configDetail.candidate.groupId
                                        ? _c(
                                            "a-card",
                                            { attrs: { title: "条件列表" } },
                                            [
                                              _c(
                                                "jtl-button",
                                                {
                                                  staticClass:
                                                    "table-header-button",
                                                  attrs: {
                                                    slot: "extra",
                                                    type: "primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.conditionOpen()
                                                    },
                                                  },
                                                  slot: "extra",
                                                },
                                                [_vm._v("+ 添加")]
                                              ),
                                              _c("a-table", {
                                                attrs: {
                                                  columns: _vm.conditionColumns,
                                                  "data-source":
                                                    _vm.configDetail.candidate
                                                      .conditions,
                                                  pagination: false,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "index",
                                                      fn: function (
                                                        text,
                                                        record,
                                                        index
                                                      ) {
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                index + 1
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: "action",
                                                      fn: function (
                                                        text,
                                                        record
                                                      ) {
                                                        return [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "jtl-edit-link",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.conditionOpen(
                                                                      record
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("编辑")]
                                                          ),
                                                          _c("a-divider", {
                                                            attrs: {
                                                              type: "vertical",
                                                            },
                                                          }),
                                                          _c(
                                                            "a-popconfirm",
                                                            {
                                                              attrs: {
                                                                title:
                                                                  "确认删除?",
                                                                "ok-text":
                                                                  "确认",
                                                                "cancel-text":
                                                                  "取消",
                                                              },
                                                              on: {
                                                                confirm:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.deleteConditionClick(
                                                                      record
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "jtl-del-link",
                                                                },
                                                                [_vm._v("删除")]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1095164876
                                                ),
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  : [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "red",
                                            "margin-right": "5px",
                                          },
                                        },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v("受理组： "),
                                      _c("a-tree-select", {
                                        staticClass: "margin-right-24",
                                        attrs: {
                                          placeholder: "请选择受理组",
                                          "tree-data":
                                            _vm.candidateGroupOptions,
                                        },
                                        on: { change: _vm.groupChanged },
                                        model: {
                                          value:
                                            _vm.configDetail.candidate.groupId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.configDetail.candidate,
                                              "groupId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "configDetail.candidate.groupId",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            color: "red",
                                            "margin-right": "5px",
                                          },
                                        },
                                        [_vm._v("*")]
                                      ),
                                      _vm._v("受理人： "),
                                      _c("a-select", {
                                        attrs: {
                                          placeholder: "请选择受理人",
                                          options: _vm.userOptions,
                                        },
                                        model: {
                                          value:
                                            _vm.configDetail.candidate.userId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.configDetail.candidate,
                                              "userId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "configDetail.candidate.userId",
                                        },
                                      }),
                                    ],
                                _c("hr", {
                                  staticStyle: { "border-width": "0px" },
                                }),
                                _c(
                                  "jtl-button",
                                  {
                                    staticStyle: { "margin-top": "24px" },
                                    attrs: { type: "primary" },
                                    on: { click: _vm.saveCandidate },
                                  },
                                  [_vm._v("保存")]
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm.selectedTreeData.task
                          ? _c(
                              "a-tab-pane",
                              {
                                key: "ccConfig",
                                staticStyle: { padding: "10px" },
                                attrs: { tab: "抄送人配置" },
                              },
                              [
                                _c(
                                  "a-radio-group",
                                  {
                                    staticStyle: { margin: "0 0 10px 0" },
                                    on: { change: _vm.ccConfigChanged },
                                    model: {
                                      value: _vm.configDetail.ccConfig.type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.configDetail.ccConfig,
                                          "type",
                                          $$v
                                        )
                                      },
                                      expression: "configDetail.ccConfig.type",
                                    },
                                  },
                                  [
                                    _c(
                                      "a-radio",
                                      { attrs: { value: "User" } },
                                      [_vm._v("固定人")]
                                    ),
                                    _c(
                                      "a-radio",
                                      { attrs: { value: "Role" } },
                                      [_vm._v("固定角色")]
                                    ),
                                    _c(
                                      "a-radio",
                                      { attrs: { value: "Group" } },
                                      [_vm._v("固定组")]
                                    ),
                                  ],
                                  1
                                ),
                                _c("hr", {
                                  staticStyle: {
                                    border: "#cdcdcd 0.5px solid",
                                    "margin-bottom": "24px",
                                  },
                                }),
                                _vm.configDetail.ccConfig.type === "Group"
                                  ? [
                                      _vm._v("受理组： "),
                                      _c("a-tree-select", {
                                        staticClass: "margin-right-24",
                                        attrs: {
                                          "allow-clear": "",
                                          placeholder: "请选择受理组",
                                          "tree-data":
                                            _vm.candidateGroupOptions,
                                        },
                                        on: {
                                          change: _vm.ccConfigGroupChanged,
                                        },
                                        model: {
                                          value:
                                            _vm.configDetail.ccConfig.groupId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.configDetail.ccConfig,
                                              "groupId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "configDetail.ccConfig.groupId",
                                        },
                                      }),
                                    ]
                                  : _vm.configDetail.ccConfig.type === "Role"
                                  ? [
                                      _c(
                                        "a-radio-group",
                                        {
                                          staticStyle: { margin: "0 0 10px 0" },
                                          attrs: { "default-value": "creator" },
                                          model: {
                                            value:
                                              _vm.configDetail.ccConfig.roleId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.configDetail.ccConfig,
                                                "roleId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "configDetail.ccConfig.roleId",
                                          },
                                        },
                                        [
                                          _c(
                                            "a-radio",
                                            {
                                              style: _vm.radioStyle,
                                              attrs: { value: "creator" },
                                            },
                                            [_vm._v("创建人")]
                                          ),
                                          _c(
                                            "a-radio",
                                            {
                                              style: _vm.radioStyle,
                                              attrs: {
                                                value: "creator-leader",
                                              },
                                            },
                                            [_vm._v("创建人直属领导")]
                                          ),
                                          _c(
                                            "a-radio",
                                            {
                                              style: _vm.radioStyle,
                                              attrs: {
                                                value: "processor-leader",
                                              },
                                            },
                                            [_vm._v("处理人直属领导")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : [
                                      _vm._v("受理组： "),
                                      _c("a-tree-select", {
                                        staticClass: "margin-right-24",
                                        attrs: {
                                          "allow-clear": "",
                                          placeholder: "请选择受理组",
                                          "tree-data":
                                            _vm.candidateGroupOptions,
                                        },
                                        on: {
                                          change: _vm.ccConfigGroupChanged,
                                        },
                                        model: {
                                          value:
                                            _vm.configDetail.ccConfig.groupId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.configDetail.ccConfig,
                                              "groupId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "configDetail.ccConfig.groupId",
                                        },
                                      }),
                                      _vm._v("受理人： "),
                                      _c("a-select", {
                                        attrs: {
                                          placeholder: "请选择受理人",
                                          options: _vm.userOptions,
                                          "allow-clear": "",
                                        },
                                        model: {
                                          value:
                                            _vm.configDetail.ccConfig.userId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.configDetail.ccConfig,
                                              "userId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "configDetail.ccConfig.userId",
                                        },
                                      }),
                                    ],
                                _c("hr", {
                                  staticStyle: { "border-width": "0px" },
                                }),
                                _c(
                                  "jtl-button",
                                  {
                                    staticStyle: { "margin-top": "24px" },
                                    attrs: { type: "primary" },
                                    on: { click: _vm.saveCCConfig },
                                  },
                                  [_vm._v("保存")]
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                        !_vm.selectedTreeData.task
                          ? _c(
                              "a-tab-pane",
                              { key: "addition", attrs: { tab: "附件配置" } },
                              [
                                _c(
                                  "a-card",
                                  { attrs: { title: "备注配置" } },
                                  [
                                    _vm._v(" 名称： "),
                                    _c("a-input", {
                                      staticStyle: {
                                        width: "200px",
                                        "margin-right": "30px",
                                      },
                                      model: {
                                        value:
                                          _vm.configDetail.addition.commentName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.configDetail.addition,
                                            "commentName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "configDetail.addition.commentName",
                                      },
                                    }),
                                    _c(
                                      "a-checkbox",
                                      {
                                        model: {
                                          value:
                                            _vm.configDetail.addition
                                              .commentVisible,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.configDetail.addition,
                                              "commentVisible",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "configDetail.addition.commentVisible",
                                        },
                                      },
                                      [_vm._v("显示")]
                                    ),
                                    _vm.configDetail.addition.commentVisible
                                      ? _c(
                                          "a-checkbox",
                                          {
                                            on: {
                                              change: function ($event) {
                                                return _vm.requireChanged(
                                                  "comment"
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.configDetail.addition
                                                  .commentRequired,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.configDetail.addition,
                                                  "commentRequired",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "configDetail.addition.commentRequired",
                                            },
                                          },
                                          [_vm._v("必填")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "a-card",
                                  {
                                    staticStyle: { "margin-top": "20px" },
                                    attrs: { title: "图片配置" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          color: "#cdcdcd",
                                          "font-size": "10px",
                                          "margin-bottom": "10px",
                                        },
                                      },
                                      [_vm._v("*最多上传9张图片")]
                                    ),
                                    _vm._v(" 名称： "),
                                    _c("a-input", {
                                      staticStyle: {
                                        width: "200px",
                                        "margin-right": "30px",
                                      },
                                      model: {
                                        value:
                                          _vm.configDetail.addition.pictureName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.configDetail.addition,
                                            "pictureName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "configDetail.addition.pictureName",
                                      },
                                    }),
                                    _c(
                                      "a-checkbox",
                                      {
                                        model: {
                                          value:
                                            _vm.configDetail.addition
                                              .pictureVisible,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.configDetail.addition,
                                              "pictureVisible",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "configDetail.addition.pictureVisible",
                                        },
                                      },
                                      [_vm._v("显示")]
                                    ),
                                    _vm.configDetail.addition.pictureVisible
                                      ? _c(
                                          "a-checkbox",
                                          {
                                            on: {
                                              change: function ($event) {
                                                return _vm.requireChanged(
                                                  "commentPicture"
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.configDetail.addition
                                                  .pictureRequired,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.configDetail.addition,
                                                  "pictureRequired",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "configDetail.addition.pictureRequired",
                                            },
                                          },
                                          [_vm._v("必填")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "jtl-button",
                                  {
                                    staticStyle: { "margin-top": "24px" },
                                    attrs: { type: "primary" },
                                    on: { click: _vm.saveAddition },
                                  },
                                  [_vm._v("保存")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("work-order-detail-condition", {
        ref: "condition",
        attrs: {
          visible: _vm.conditionVisble,
          record: _vm.selectedConditionItem,
          "view-type": _vm.conditionViewType,
          fields: _vm.fields,
        },
        on: { closeCondition: _vm.closeCondition },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }