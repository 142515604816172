




























































































































































































import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import WorkOrderDetailConditionComponent from './work-order-config-detail-condition.vue';
import CommonService from '@common-src/service/common';
import WorkOrderConfigDetailService from '@common-src/service/work-order-config-detail';
import WorkOrderConfigListService from '@common-src/service/work-order-config-list';
import WorkOrderFormDesignService from '@common-src/service/work-order-form-design';
import { SimpleTreeModel } from '@common-src/model/simple-tree-model';
import { WorkOrderConfigDetailEntityModel } from '@common-src/entity-model/work-order-config-detail-entity';
import { WorkOrderConfigQueryModel } from '@common-src/entity-model/work-order-config-entity';
import { ViewModeType } from '@common-src/model/enum';

@Component({
    components: {
        'work-order-detail-condition': WorkOrderDetailConditionComponent
    }
})
export default class WorkOrderConfigDetailComponent extends BaseComponent {
    title: string = '';
    treeData: any = [];
    expandedKeys: any = [];
    flowOptions: any = [];
    detailId:string = undefined;
    selectedGroupId: any = [];
    selectedTreeData: any = { key: '', title: '', task: true };

    // selectedTab:string = 'form' || 'candidate' || 'addition'
    // 加载选中tab对应数据,默认为表单权限tab
    selectedTab: string = 'candidate';
    configDetail: any = {
        form: [],
        candidate: { id: '', type: 'User', groupId: '', userId: '', roleId: '', professionId: '', actionId: '', conditions: [] },
        ccConfig: { id: '', type: 'User', groupId: '', userId: '', roleId: '' },
        addition: { commentId: '', commentName: '意见', commentRequired: false, commentVisible: true, pictureId: '', pictureName: '意见', pictureRequired: false, pictureVisible: false }
    };
    originConfigDetail: any = {};
    candidateGroupOptions: SimpleTreeModel[] = [];
    userOptions: Array<{label:string, value:string}> = [];

    // 表单权限TableColumns
    formColumns: any = [
        {
            title: '序号',
            scopedSlots: { customRender: 'index' },
            width: 60
        },
        {
            title: '字段名称',
            dataIndex: 'displayName'
        },
        {
            title: '显示',
            dataIndex: 'visible',
            scopedSlots: { customRender: 'checkBox' }
        },
        {
            title: '只读',
            dataIndex: 'readonly',
            scopedSlots: { customRender: 'checkBox' }

        },
        {
            title: '必填项',
            dataIndex: 'required',
            scopedSlots: { customRender: 'checkBox' }
        }
    ];

    // 垂直排列radio
    radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px'
    };

    // 处理人配置-条件分派-条件列表TableColumns
    conditionColumns = WorkOrderConfigDetailEntityModel.getTableColumns();
    selectedConditionItem: WorkOrderConfigDetailEntityModel = new WorkOrderConfigDetailEntityModel();
    conditionVisble: boolean = false;
    conditionViewType: any = ViewModeType.NEW;
    fields: any = [];

    get SelectedGroupId() {
        return [this.selectedGroupId];
    }

    set SelectedGroupId(value) {}

    created() {
        // 获取服务配置ID
        this.detailId = this.$route.params.id;

        WorkOrderConfigListService.query(new WorkOrderConfigQueryModel(), 1, 999).then(res => {
            this.title = _.filter(res.items, item => { return item.id === this.detailId; })[0].serviceName;

            let formId = _.filter(res.items, item => { return item.id === this.detailId; })[0].formId;

            new WorkOrderFormDesignService(formId).query().then(res => {
                this.fields = res;
            });
        });

        CommonService.getOrgs().then(res => {
            this.candidateGroupOptions = res;
        });

        // 获取左侧树形数据
        WorkOrderConfigDetailService.getFlowTreeData(this.detailId).then(res => {
            this.treeData = res;

            this.expandedKeys = _.map(this.treeData, res => {
                return res.key;
            });

            this.flowOptions = [];

            _.map(res, item => {
                this.flowOptions.push({ label: item.title, value: item.key });
            });

            this.selectedGroupId = (_.first(res) as any).key;

            this.selectedTreeData = _.first(res);

            this.reload();
        });
    }
    tabChanged(key) {
        this.selectedTab = key;

        this.reload();
    }

    candidateChanged(value) {
        const saveValue = this.originConfigDetail.candidate;
        const SameGroup = saveValue.type === value.target.value;

        this.configDetail.candidate.groupId = SameGroup ? saveValue.groupId : undefined;

        this.configDetail.candidate.userId = SameGroup ? saveValue.userId : undefined;

        this.configDetail.candidate.actionId = SameGroup ? saveValue.actionId : undefined;
        if ((value.target.value === 'User' || value.target.value === 'Role' || value.target.value === 'Profession' || value.target.value === 'Condition') && this.configDetail.candidate.groupId) {
            this.groupChanged(this.configDetail.candidate.groupId);
        }
    }

    ccConfigChanged(value) {
        const saveValue = this.originConfigDetail.ccConfig;
        const SameGroup = saveValue.type === value.target.value;

        this.configDetail.ccConfig.groupId = SameGroup ? saveValue.groupId : undefined;

        this.configDetail.ccConfig.userId = SameGroup ? saveValue.userId : undefined;

        if ((value.target.value === 'User' || value.target.value === 'Role' || value.target.value === 'Profession' || value.target.value === 'Condition') && this.configDetail.ccConfig.groupId) {
            this.ccConfigGroupChanged(this.configDetail.ccConfig.groupId);
        }
    }

    ccConfigGroupChanged(key) {
        CommonService.getOrgUserList(key).then(res => {
            this.userOptions = _.map(res, item => {
                return {
                    value: item.value,
                    label: item.name
                };
            });

            let val = _.filter(this.userOptions, item => { return item.value === this.configDetail.ccConfig.userId; });

            if (val.length <= 0) {
                this.configDetail.ccConfig.userId = '';
            }
        });
    }

    groupChanged(key) {
        CommonService.getOrgUserList(key).then(res => {
            this.userOptions = _.map(res, item => {
                return {
                    value: item.value,
                    label: item.name
                };
            });

            let val = _.filter(this.userOptions, item => { return item.value === this.configDetail.candidate.userId; });

            if (val.length <= 0) {
                this.configDetail.candidate.userId = '';
            }

            // if (this.configDetail.candidate.type === 'Condition') {
            //     this.saveCandidate();
            // }
        });
    }
    treeSelect(groupIds: Array<string>, e) {
        this.selectedGroupId = _.first(groupIds);

        this.selectedTreeData = (_.first(e.selectedNodes) as any).data.props.dataRef;

        if (this.selectedTreeData && this.selectedTreeData.task) {
            this.selectedTab = 'candidate';
        } else {
            this.selectedTreeData.task = false;
            this.selectedTab = 'form';
        }

        this.reload();
    }

    reload() {
        if (this.selectedTab === 'form') {
            WorkOrderConfigDetailService.getFields(this.detailId, this.selectedGroupId).then(res => {
                this.configDetail.form = res;
                this.originConfigDetail.form = _.cloneDeep(res);
            });
        } else if (this.selectedTab === 'candidate') {
            WorkOrderConfigDetailService.getCandidate(this.detailId, this.selectedGroupId).then(res => {
                this.configDetail.candidate = res;
                this.originConfigDetail.candidate = _.cloneDeep(res);
                if ((this.configDetail.candidate.type !== 'Group' && this.configDetail.candidate.type !== 'OtherCondition') && this.configDetail.candidate.groupId) {
                    this.groupChanged(this.configDetail.candidate.groupId);
                }
            });
        } else if (this.selectedTab === 'ccConfig') {
            WorkOrderConfigDetailService.getCCConfig(this.detailId, this.selectedGroupId).then(res => {
                this.configDetail.ccConfig = res;
                this.originConfigDetail.ccConfig = _.cloneDeep(res);
                if ((this.configDetail.ccConfig.type !== 'Group') && this.configDetail.ccConfig.groupId) {
                    this.ccConfigGroupChanged(this.configDetail.ccConfig.groupId);
                }
            });
        } else if (this.selectedTab === 'addition') {
            WorkOrderConfigDetailService.getAddition(this.detailId, this.selectedGroupId).then(res => {
                this.configDetail.addition = res;
                this.originConfigDetail.addition = _.cloneDeep(res);
            });
        }
    }

    saveForm() {
        let params = _.map(this.configDetail.form, item => {
            return {
                id: item.id,
                required: item.required,
                visible: item.visible,
                readonly: item.readonly
            };
        });

        WorkOrderConfigDetailService.saveFields(this.detailId, this.selectedGroupId, params).then(res => {
            this.showMessageSuccess('保存成功');
        });
    }

    conditionOpen(record) {
        if (!record) {
            this.selectedConditionItem = new WorkOrderConfigDetailEntityModel();

            this.conditionViewType = ViewModeType.NEW;
        } else {
            this.selectedConditionItem = record;

            this.conditionViewType = ViewModeType.UPDATE;
        }

        this.selectedConditionItem.assignmentId = this.configDetail.candidate.id;

        this.conditionVisble = true;
    }

    closeCondition(e) {
        if (e) {
            this.saveCandidate();
        }
        this.conditionVisble = false;
    }

    deleteConditionClick(record) {
        record.assignmentId = this.configDetail.candidate.id;

        WorkOrderConfigDetailService.delete(record).then(res => {
            this.showMessageSuccess('删除成功');

            this.reload();
        });
    }

    saveCandidate() {
        let flag = true;

        switch (this.configDetail.candidate.type) {
            case 'User':
                if (!this.configDetail.candidate.groupId) {
                    this.showMessageWarning('受理组为必填项');

                    flag = false;
                }
                if (!this.configDetail.candidate.userId) {
                    this.showMessageWarning('受理组为必填项');

                    flag = false;
                }
                break;
            case 'Group':
            case 'Role':
            case 'Profession':
            case 'Condition':
                if (!this.configDetail.candidate.groupId) {
                    this.showMessageWarning('默认受理组为必填项');

                    flag = false;
                }
                break;
            case 'OtherCondition':
                if (!this.configDetail.candidate.actionId) {
                    this.showMessageWarning('流程节点为必填项');

                    flag = false;
                }
                break;
        }

        if (!flag) {
            return;
        }

        WorkOrderConfigDetailService.saveCanditate(this.configDetail.candidate).then(res => {
            this.reload();
            this.showMessageSuccess('保存成功');
        });
    }

    saveCCConfig() {
        // let flag = true;

        // switch (this.configDetail.ccConfig.type) {
        //     case 'User':
        //         if (!this.configDetail.ccConfig.groupId) {
        //             this.showMessageWarning('受理组为必填项');

        //             flag = false;
        //         }
        //         if (!this.configDetail.ccConfig.userId) {
        //             this.showMessageWarning('受理组为必填项');

        //             flag = false;
        //         }
        //         break;
        //     case 'Group':
        //         if (!this.configDetail.ccConfig.groupId) {
        //             this.showMessageWarning('默认受理组为必填项');
        //             flag = false;
        //         }
        //         break;
        // }

        // if (!flag) {
        //     return;
        // }

        WorkOrderConfigDetailService.saveCCConfig(this.configDetail.ccConfig).then(res => {
            this.showMessageSuccess('保存成功');
        });
    }

    requireChanged(type) {
        if (type === 'comment') {
            if (this.configDetail.addition.commentRequired) {
                this.configDetail.addition.commentVisible = true;
            }
        } else {
            if (this.configDetail.addition.pictureRequired) {
                this.configDetail.addition.pictureVisible = true;
            }
        }
    }

    saveAddition() {
        WorkOrderConfigDetailService.saveAddition(this.configDetail.addition).then(res => {
            this.showMessageSuccess('保存成功');
        });
    }
}
